
import React, {Component} from "react";
import './App.css';

// import map library
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
  useZoomPan
} from "react-simple-maps";


// import centroids geographic coordinates
import centroids_coordinates from "./country-centroids-coordinates.json";

// import the dataset
import dataset from "./covid-data.json";

const death_indicator = (amount)=>((1 / (Math.log(amount) / 2.5)) == 0 || amount == 1 ? 1 : 1 / (Math.log(amount) / 2.5))
const case_indicator = (amount) => (100*Math.cbrt(amount/100000))

let vh_in_pixel = document.documentElement.clientHeight ;
let vw_in_pixel = document.documentElement.clientWidth ;

// putting centroids in order
let centroids_list = {}
Array.from(centroids_coordinates).forEach(function(elem){
  centroids_list[elem.name] = {long: elem.long, lat: elem.lat};
})
/* ---------------------- *
* putting the data in order
* ----------------------- */
let handful = [];
// console.log('countries count : ', Object.keys(dataset).length)
for (const entry in dataset){
  // pick the wanted columns (location, data, population)
  handful.push({location: dataset[entry].location, data: dataset[entry].data, population: dataset[entry].population});
}
// console.log(handful);
// drop the few countries with missing data before March 1st
handful = handful.filter((elem) => {
  return Date.parse(elem.data[0].date)<=1585699200000
});

// drop the useless data (before April 1st et after January 6st) is remaining countries
let filtered_countries = []
let blacklist = ["Hong Kong", "Cape Verde", "Kosovo", "Eswatini", "World", "Vatican", "Bhutan", "Cambodia", "Dominica", "Eritrea", "Grenada", "Mongolia", "Saint Kitts and Nevis", "Timor", "Seychelles", "Laos", "Fiji", "Saint Vincent and the Grenadines", "Saint Lucia"]
handful.forEach(function(country){
  let filtered_data = country.data.filter(data => {
    return Date.parse(data.date)>=1585699200000 && Date.parse(data.date)<1609891200000
  })
  // also drop Hong Kong who slipped through above filter but doesn't have data
  if(!blacklist.includes(country.location)){
    filtered_countries.push({location: country.location, data: filtered_data, population: country.population});
  };
})

// sort
filtered_countries.sort(function(a, b){
  return Date.parse(a.data[0].date)-Date.parse(b.data[0].date)
});


// process the data toward workable array with weekly values
let processed_data = []
filtered_countries.forEach(function(c, i){
  // initialize processed data array
  let processed_country = {
    ...centroids_list[c.location],
    location: c.location,
    population: c.population,
    data : []
  }

  // build weekly data
  let day_index = 1
  let week_cases = 0
  let week_deaths = 0
  let week_marks = ""
  c.data.forEach(function(day){
    if(day_index == 1) week_marks = new Date(Date.parse(day.date)).toLocaleDateString('en-EN', {day: 'numeric' , month: 'short'})
    if(day_index !== 7){
      if(day.new_cases<0){ week_cases -= day.new_cases }else{ week_cases += day.new_cases }
      if(day.new_deaths<0){ week_deaths -= day.new_deaths }else{ week_deaths += day.new_deaths }
      day_index ++
    }else{
      week_marks += " - " + new Date(Date.parse(day.date)).toLocaleDateString('en-EN', {day: 'numeric' , month: 'short'})
      let ratio = week_deaths/week_cases;
      if(isNaN(week_deaths)){week_deaths=0}
      if(isNaN(week_cases)){
        if(c.location == "Malawi"){week_cases=0}
        if(c.location == "Turkey"){week_cases=32000}
      }
      if(isNaN(ratio) || ratio == Infinity){ ratio = 0 }
      processed_country.data.push({
        label: week_marks,
        new_cases: week_cases,
        new_cases_indicator: Math.cbrt(week_cases/100000),
        new_deaths: week_deaths,
        new_deaths_indicator: death_indicator(week_deaths)
      })
      if(week_cases)
      day_index = 1
      week_cases = 0
      week_deaths = 0
    }
  })
  processed_data.push(processed_country);
})

// let deaths = []
// processed_data.forEach(function(c){
//   c.data.forEach(function(day){
//     deaths.push(parseInt(day.new_deaths));
//     if(isNaN(day.new_deaths)){console.log(c.location, day)}
//   })
// })
// deaths.sort((a, b)=>(a-b));
// console.log((deaths))
// console.log(Math.max(...deaths))


// pick a world map projection
const GEO_URL =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

class App extends Component{

  constructor(props) {
    super(props);
    this.state = {
      currentWeek: 0,
      toggle: true
    }

    this.toggle = this.toggle.bind(this);
  }

  handleSlider(e){
    this.setState({
      currentWeek: e.target.value
    })
  }
  handleColor(){
    return
  }
  componentDidMount() {
    let markers = Array.from(document.querySelectorAll('.marker'));
    markers.forEach(function(marker){
      marker.addEventListener('mouseenter', function(element){
        let elem = element.target
        let targ_rect = elem.getBoundingClientRect()
        let caption = document.createElement('div');
        caption.classList.add("caption")
        caption.innerHTML = "<p>" + elem.dataset.country + "</p><p>Week " + elem.dataset.week + "</p><p>" + elem.dataset.cases + " new cases</p><p>" + elem.dataset.deaths + " new deaths</p>"
        document.body.append(caption);
        caption.style.left = targ_rect.left + targ_rect.width/2 - caption.clientWidth/2 + "px";
        caption.style.top = targ_rect.top + targ_rect.height/2 - caption.clientHeight/2 + "px";
        caption.style.opacity = "0.7";
        console.log(elem.dataset.country)
        elem.style.opacity = 1
      })
      marker.addEventListener('mouseleave', function(element){
        let elem = element.target
        Array.from(document.querySelectorAll("div.caption")).forEach(function(caption){
          caption.style.opacity = 0;

        })
        elem.style.opacity = 0.8
      })
    })
  }

  toggle(){
    this.setState((prev)=>{
      let toggle = prev.toggle ? false : true;
      return {toggle : toggle}
    })
  }
  render(){

    const {currentWeek, toggle} = this.state;

    return (
      <main id="app_container">
        <h1>Weekly contamination and deaths per country in 2020 </h1>
        <hr/>
        <section id={"center_section"} className={toggle && "show_analyse"}>
          <div className={"legends"}>
            <div className={"size"}>
              <h4>CASES</h4>
              <div className={"p"}><div className={"legend_size"} style={{height: 1.15*case_indicator(500000)+"px", width: 1.15*case_indicator(500000)+"px"}}></div><span>500 000</span></div>
              <div className={"p"}><div className={"legend_size"} style={{height: 1.15*case_indicator(50000)+"px", width: 1.15*case_indicator(50000)+"px"}}></div><span>50 000</span></div>
              <div className={"p"}><div className={"legend_size"} style={{height: 1.15*case_indicator(5000)+"px", width: 1.15*case_indicator(5000)+"px"}}></div><span>5000</span></div>
              <div className={"p"}><div className={"legend_size"} style={{height: 1.15*case_indicator(50)+"px", width: 1.15*case_indicator(50)+"px"}}></div><span>50</span></div>
            </div>
            <div className={"color"}>
              <h4>DEATHS</h4>
              <div className={"p"}><div className={"legend_color"} style={{backgroundColor:"rgb(255, "
                  + 255*death_indicator(10) + ", "
                  + 255*death_indicator(10) + ")"}}></div>10</div>
              <div className={"p"}><div className={"legend_color"} style={{backgroundColor:"rgb(255, "
                  + 255*death_indicator(100) + ", "
                  + 255*death_indicator(100) + ")"}}></div>100</div>
              <div className={"p"}><div className={"legend_color"} style={{backgroundColor:"rgb(255, "
                  + 255*death_indicator(2000) + ", "
                  + 255*death_indicator(2000) + ")"}}></div>2 000</div>
              <div className={"p"}><div className={"legend_color"} style={{backgroundColor:"rgb(255, "
                  + 255*death_indicator(20000) + ", "
                  + 255*death_indicator(20000) + ")"}}></div>20 000</div>
            </div>
          </div>
          <ComposableMap className={"graph"} style={{maxHeight: '84vh', maxWidth: "100vw"}}>
            <CustomZoomableGroup center={[0, 0]}>
              {position => (
                <>
                  <Geographies geography={GEO_URL}>
                    {({ geographies }) =>
                      geographies.map(geo => (
                        <Geography key={geo.rsmKey}
                                   geography={geo}
                                   fill={"#222"}
                                   stroke={"white"}
                                   strokeWidth={"0.25"}
                                   style={{
                                     // hoverStroke: 'red',
                                     default: {
                                       outline: 'none'
                                     },
                                     hover: {
                                       outline: 'none',
                                       // fill: 'red'
                                     },
                                     pressed: {
                                       outline: 'none'
                                     }
                                   }}
                        />
                      ))
                    }
                  </Geographies>
                  {
                    processed_data.map(function(country, i){
                      return(
                        <Marker key={i} coordinates={[country.long, country.lat]}>
                          <svg className={"marker"}
                               data-country={country.location}
                               data-cases={country.data[currentWeek].new_cases}
                               data-deaths={country.data[currentWeek].new_deaths}
                               data-week={processed_data[0].data[currentWeek].label}
                               width={100*country.data[currentWeek].new_cases_indicator}
                               height={100*country.data[currentWeek].new_cases_indicator}
                               xmlns={"http://www.w3.org/2000/svg"}
                               x={-(100*country.data[currentWeek].new_cases_indicator)/2}
                               y={-(100*country.data[currentWeek].new_cases_indicator)/2}
                               style={{opacity: 0.80}}
                          >
                            <defs>
                              <radialGradient id={"MyGradient"+i}>
                                <stop
                                  offset={"95%"}
                                  stopColor={"rgb(255, "
                                  + 255*(country.data[currentWeek].new_deaths_indicator  == 0 ? 1 : country.data[currentWeek].new_deaths_indicator)  + ", "
                                  + 255*(country.data[currentWeek].new_deaths_indicator  == 0 ? 1 : country.data[currentWeek].new_deaths_indicator)  + ")"}/>
                                <stop offset={"100%"} stopColor={"transparent"}
                                />
                              </radialGradient>
                            </defs>

                            <circle
                              fill={"url(#MyGradient"+i+")"}
                              cx={(100*country.data[currentWeek].new_cases_indicator)/2}
                              cy={(100*country.data[currentWeek].new_cases_indicator)/2}
                              r={(100*country.data[currentWeek].new_cases_indicator)/2/position.k}
                            />
                          </svg>
                        </Marker>
                      )
                    })
                  }
                </>
              )}

            </CustomZoomableGroup>
          </ComposableMap>
          <div id={"toggle"} onClick={this.toggle}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={"analyse"}>
            <h4>Analysis</h4>
            <p>Across the world, all countries have been struck by the virus. But we can see significant differences in some of them.</p>
            <p>The Shengen Area has had some trouble keeping down all the international flows. On the other hand, African countries which have not as much international traffic, were less affected.</p>
            <p>Zooming on certain countries, like France, we can see the impact of containment periods on the Covid-19 consequences.</p>
            <p>Finally, some countries like Brasil or the USA, which struggled to acknowledge what a serious issue the virus was, and which didn't react on time, are the most afflicted by the coronavirus.</p>
            <p style={{position:'absolute', bottom:'20px', fontStyle:"italic"}}>Data by World Health Organization and New York Times, through https://data.world/</p>
          </div>
        </section>
        <form>
          <input type={"range"} min={0} max={39} onChange={(e) => this.handleSlider(e)} value={currentWeek}/>

            <h3>{processed_data[0].data[currentWeek].label}</h3>
        </form>
        <div id={"calendar"}>
          <div style={{display:'flex'}}>
            {processed_data[0].data.map((week, i)=>(
              <div key={i} className={"week" + (i == currentWeek ? " current" : "") + ([3, 8, 12, 16, 21, 25, 30, 34, 38, 39].includes(i) ? " month_end" : "")}></div>
            ))}
          </div>
          <div className={"year"}>
            <div className="month w4">April</div>
            <div className="month w5">May</div>
            <div className="month w4">June</div>
            <div className="month w4">July</div>
            <div className="month w5">August</div>
            <div className="month w4">September</div>
            <div className="month w5">October</div>
            <div className="month w4">November</div>
            <div className="month w4">December</div>
            <div className="month w1">January</div>
          </div>
        </div>
      </main>
    );
  }
}
const width = vw_in_pixel;
const height = 0.8*vh_in_pixel;

const CustomZoomableGroup = ({ children, ...restProps }) => {
  const { mapRef, transformString, position } = useZoomPan(restProps);
  return (
    <g ref={mapRef}>
      <rect width={width} height={height} fill="transparent" />
      {/*<rect fill="transparent" />*/}
      <g transform={transformString}>{children(position)}</g>
    </g>
  );
};


export default App;